import '../../src/components/slider/center-scale/center-scale';
import '../../src/components/map/marker-multiple/marker-multiple';
import * as video from '../../src/components/video/default/default';

video.init();

$(function () {

	/*
	 * 1 - Mask & Validation
	 * 2 - Swiper Default
	 * 3 - Tab Nav Mobile
	 */

	// 1
	// ========================================================================

	let modalLinks = document.querySelectorAll('p a:not([target])');
	UIkit.util.each(modalLinks, function (e) {
		if (UIkit.util.isNode(e)) {
			UIkit.toggle(e);
		}
	});

	$('[type="tel"]').mask('(000) 000-0000');

	$.validator.addMethod("lettersonly", function (value, element) {
		return this.optional(element) || /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(value);
	});

	let message = "Lütfen ilgili alanı doldurunuz.";

	$('#contact-form').validate({
		rules: {
			name: {
				required: true,
				lettersonly: true
			},
			company: {
				required: true,
			},
			subject: {
				required: true,
			},
			email: {
				required: true,
				email: true
			},
			phone: {
				required: true,
				minlength: 14
			},
			message: {
				required: true,
			}
		},
		messages: {
			name: message,
			company: message,
			subject: message,
			phone: message,
			email: message,
			message: message
		},
		submitHandler: function () {
			let form = document.getElementById('contact-form');
			let alertModal = document.querySelector('.contact-popup');
			let oData = new FormData(form);

			let oReq = new XMLHttpRequest();
			oReq.open("POST", "/web/jx-sendmail", true);

			form.querySelector("[type='submit']").setAttribute("disabled", "disabled");

			oReq.onload = function () {
				let response = JSON.parse(oReq.responseText);
				if (oReq.status === 200) {
					if (response.success) {
						alertModal.querySelector('.content').innerHTML = response.message;
						UIkit.modal('.contact-popup').show();
					} else {
						alertModal.querySelector('.content').innerHTML = response.message;
						UIkit.modal('.contact-popup').show();
					}
				} else {
					alertModal.querySelector('.content').innerHTML = "Bir hata meydana geldi";
					UIkit.modal('.contact-popup').show();
				}
				form.querySelector("[type='submit']").removeAttribute('disabled');
			};

			oReq.send(oData);
		}
	});

	// 2 - Swiper Default
	// ========================================================================

	//var swiper = new Swiper('.swiper-container', {});

	// 3 - Tab Nav Mobile
	// ========================================================================

	/*var total = 0;
	var padding = 0;
	var tabNav = $('.tab-default .uk-tab');

	tabNav.find('li').each(function () {
		total += parseInt(
			$(this).width()
		);

		padding += parseInt(
			$(this).css('padding-left')
		);

	});

	tabNav.width(total + padding + 4);*/

	UIkit.lightbox('.swiper-lightbox', {});

});

const contactSelect = document.querySelector('#contact-form select');
const fileButton = document.querySelector('#contact-form .add-file');
if (contactSelect) {
	let selectVal = parseInt(contactSelect.value);
	if (selectVal === 0) {
		fileButton.classList.remove('uk-hidden');
	} else {
		fileButton.classList.add('uk-hidden');
	}

	contactSelect.addEventListener('change', (event) => {
		let selectVal = parseInt(event.target.value);
		console.log(selectVal);
		if (selectVal === 0) {
			fileButton.classList.remove('uk-hidden');
		} else {
			fileButton.classList.add('uk-hidden');
		}
	});
}
