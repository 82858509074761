function sliderInit() {
    window.centerScale1 = new Swiper('.swiper-center-scale', {
        slidesPerView: 3,
        spaceBetween: 70,
        centeredSlides: true,
        loop: true,
        parallax: true,
        navigation: {
            nextEl: '[uk-slidenav-next]',
            prevEl: '[uk-slidenav-previous]',
        }
    });
}

function sliderInit2() {
    window.centerScale2 = new Swiper('.swiper-center-scale', {
        slidesPerView: 1,
        spaceBetween: 10,
        parallax: true,
        loop: true,
        navigation: {
            nextEl: '[uk-slidenav-next]',
            prevEl: '[uk-slidenav-previous]',
        }
    });
}

function sliderResize() {
    let width = $(window).width();
    if (width < 640) {
        if (window.centerScale1 !== undefined) {
            window.centerScale1.destroy(true, true);
            window.centerScale1 = undefined;
        }
        if (window.centerScale2 === undefined) {
            sliderInit2();
        }
    } else {
        if (window.centerScale2 !== undefined) {
            window.centerScale2.destroy(true, true);
            window.centerScale2 = undefined;
        }
        if (window.centerScale1 === undefined) {
            sliderInit();
        }
    }
}

sliderResize();

$(() => {
    $(window).resize(() => {
        sliderResize();
    });
});